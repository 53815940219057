// @flow
import React from 'react';
import { graphql } from 'gatsby';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import PageHelmet from '../plugins/helmet/components/PageHelmet';
import PageWrapper from '../components/PageWrapper';
import RichText from '../plugins/prismic/components/RichText';
import type { Query } from '../schema.flow';

const styles = (unusedTheme: Theme) => ({
  root: {
    width: '100%',
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  data: Query,
};

const PrivacyPage = ({ data, classes }: Props) => {
  const privacyData = data?.prismicPolicypage?.data;
  return (
    <div className={classes.root}>
      <PageWrapper limitWidth guttersH guttersV>
        <PageHelmet data={privacyData} />
        <div>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                align="center"
                style={{ paddingBottom: '24px' }}
              >
                {privacyData?.name?.text}
              </Typography>
              {RichText.hasValue(privacyData?.description) ? (
                <Typography component="div" variant="body1">
                  <RichText {...privacyData?.description} />
                </Typography>)
              : null}
            </Grid>
          </Grid>
        </div>
      </PageWrapper>
    </div>
  );
}

export const query = graphql`
  query PrivacyPolicyQuery($prismicLocaleId: String) {
    prismicPolicypage(lang: { eq: $prismicLocaleId }) {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        name {
          text
        }
        description {
          html
        }
      }
    }
  }
`;

export default withStyles(styles)(PrivacyPage);
